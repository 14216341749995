import { useEffect } from "react";

const Sidebar1 = ({ onClose }) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div
      className="w-[312px] h-full rounded-xl bg-noble-black-800 flex flex-col items-start justify-start [&.animate]:animate-[0.25s_ease_0s_1_normal_forwards_slide-in-left] opacity-[0] max-w-[90%] overflow-auto text-center text-base text-noble-black-0 font-body-l-medium"
      data-animate-on-scroll
    >
      <div className="self-stretch flex flex-row items-center justify-between p-6 border-b-[1px] border-solid border-noble-black-700">
        <div className="flex flex-row items-center justify-start gap-[16px]">
          <img
            className="w-12 relative rounded-xl h-12"
            alt=""
            src="/avatar.png"
          />
          <div className="flex flex-col items-start justify-start gap-[4px]">
            <div className="relative tracking-[0.15px] leading-[24px] font-semibold">
              Intellisys
            </div>
            <div className="relative text-xs tracking-[0.15px] leading-[18px] font-medium text-stem-green-500">
              12 members
            </div>
          </div>
        </div>
        <img className="w-4 relative h-4" alt="" src="/chevrondown.svg" />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start py-6 px-2 gap-[24px] text-left text-xs text-noble-black-400 border-b-[1px] border-solid border-noble-black-700">
        <div className="self-stretch flex flex-row items-start justify-start py-0 px-4">
          <div className="relative tracking-[0.15px] leading-[18px] font-semibold">
            GENERAL
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[8px] text-center text-noble-black-300 font-inter">
          <div className="w-[296px] rounded-lg h-12 flex flex-row items-center justify-start py-3.5 px-4 box-border gap-[16px]">
            <div className="flex-1 flex flex-row items-center justify-start gap-[16px]">
              <img className="w-5 relative h-5" alt="" src="/search.svg" />
              <input
                className="[border:none] [outline:none] font-semibold font-body-l-medium text-sm bg-[transparent] relative tracking-[0.15px] leading-[20px] text-noble-black-100 text-center"
                placeholder="Search"
                type="text"
              />
            </div>
            <div className="rounded [background:linear-gradient(117.58deg,_rgba(215,_237,_237,_0.16),_rgba(205,_235,_235,_0))] shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.05),_0px_1px_3px_rgba(0,_0,_0,_0.05)] flex flex-row items-center justify-end py-1 px-2 border-[1px] border-solid border-glass-stroke">
              <div className="relative tracking-[0.15px] leading-[18px]">
                <span className="font-semibold">⌘</span>
                <span className="font-semibold font-body-l-medium"> S</span>
              </div>
            </div>
          </div>
          <a className="[text-decoration:none] w-[296px] rounded-lg h-12 flex flex-row items-center justify-start py-3.5 px-4 box-border gap-[16px] text-sm text-noble-black-100 font-body-l-medium">
            <div className="flex-1 flex flex-row items-center justify-start gap-[16px]">
              <img className="w-5 relative h-5" alt="" src="/creditcard.svg" />
              <div className="relative tracking-[0.15px] leading-[20px] font-semibold">
                Billing
              </div>
            </div>
            <div className="rounded [background:linear-gradient(117.58deg,_rgba(215,_237,_237,_0.16),_rgba(205,_235,_235,_0))] shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.05),_0px_1px_3px_rgba(0,_0,_0,_0.05)] hidden flex-row items-center justify-end py-1 px-2 text-xs text-noble-black-300 font-inter border-[1px] border-solid border-glass-stroke">
              <div className="relative tracking-[0.15px] leading-[18px]">
                <span className="font-semibold">⌘</span>
                <span className="font-semibold font-body-l-medium"> S</span>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="self-stretch flex-1 flex flex-col items-start justify-start py-6 px-2 gap-[24px] text-left text-xs text-noble-black-400">
        <div className="self-stretch flex flex-row items-start justify-start py-0 px-4">
          <div className="relative tracking-[0.15px] leading-[18px] font-semibold">
            PROJECTS
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[8px] text-center text-sm text-noble-black-100">
          <div className="w-[296px] rounded-lg [background:linear-gradient(117.58deg,_rgba(215,_237,_237,_0.16),_rgba(205,_235,_235,_0))] box-border h-12 flex flex-row items-center justify-start py-3.5 px-4 gap-[16px] border-t-[1px] border-solid border-glass-stroke">
            <div className="flex-1 flex flex-row items-center justify-start gap-[16px]">
              <div className="w-5 relative [filter:drop-shadow(0px_10px_15px_-3px_rgba(182,_240,_156,_0.16))_drop-shadow(0px_4px_6px_-4px_rgba(182,_240,_156,_0.16))] h-5">
                <div className="absolute h-[82.5%] w-[82.5%] top-[9%] right-[8.5%] bottom-[8.5%] left-[9%] rounded-10xs box-border border-[1.5px] border-solid border-stem-green-500" />
              </div>
              <div className="relative tracking-[0.15px] leading-[20px] font-semibold">
                Orbital Oddysey
              </div>
            </div>
            <div className="rounded [background:linear-gradient(117.58deg,_rgba(215,_237,_237,_0.16),_rgba(205,_235,_235,_0))] shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.05),_0px_1px_3px_rgba(0,_0,_0,_0.05)] hidden flex-row items-center justify-end py-1 px-2 text-xs text-noble-black-300 font-inter border-[1px] border-solid border-glass-stroke">
              <div className="relative tracking-[0.15px] leading-[18px]">
                <span className="font-semibold">⌘</span>
                <span className="font-semibold font-body-l-medium"> S</span>
              </div>
            </div>
          </div>
          <div className="w-[296px] rounded-lg h-12 flex flex-row items-center justify-start py-3.5 px-4 box-border gap-[16px]">
            <div className="flex-1 flex flex-row items-center justify-start gap-[16px]">
              <img className="w-5 relative h-5" alt="" src="/triangle.svg" />
              <div className="relative tracking-[0.15px] leading-[20px] font-semibold">
                Digital Product Launch
              </div>
            </div>
            <div className="rounded [background:linear-gradient(117.58deg,_rgba(215,_237,_237,_0.16),_rgba(205,_235,_235,_0))] shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.05),_0px_1px_3px_rgba(0,_0,_0,_0.05)] hidden flex-row items-center justify-end py-1 px-2 text-xs text-noble-black-300 font-inter border-[1px] border-solid border-glass-stroke">
              <div className="relative tracking-[0.15px] leading-[18px]">
                <span className="font-semibold">⌘</span>
                <span className="font-semibold font-body-l-medium"> S</span>
              </div>
            </div>
          </div>
          <div className="w-[296px] rounded-lg h-12 flex flex-row items-center justify-start py-3.5 px-4 box-border gap-[16px]">
            <div className="flex-1 flex flex-row items-center justify-start gap-[16px]">
              <div className="w-5 relative [filter:drop-shadow(0px_10px_15px_-3px_rgba(226,_111,_32,_0.16))_drop-shadow(0px_4px_6px_-4px_rgba(226,_111,_32,_0.16))] h-5">
                <div className="absolute h-[82.5%] w-[82.5%] top-[9%] right-[8.5%] bottom-[8.5%] left-[9%] rounded-10xs box-border border-[1.5px] border-solid border-happy-orange-600" />
              </div>
              <div className="relative tracking-[0.15px] leading-[20px] font-semibold">
                Brand Refresh
              </div>
            </div>
            <div className="rounded [background:linear-gradient(117.58deg,_rgba(215,_237,_237,_0.16),_rgba(205,_235,_235,_0))] shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.05),_0px_1px_3px_rgba(0,_0,_0,_0.05)] hidden flex-row items-center justify-end py-1 px-2 text-xs text-noble-black-300 font-inter border-[1px] border-solid border-glass-stroke">
              <div className="relative tracking-[0.15px] leading-[18px]">
                <span className="font-semibold">⌘</span>
                <span className="font-semibold font-body-l-medium"> S</span>
              </div>
            </div>
          </div>
          <div className="w-[296px] rounded-lg h-12 flex flex-row items-center justify-start py-3.5 px-4 box-border gap-[16px]">
            <div className="flex-1 flex flex-row items-center justify-start gap-[16px]">
              <img className="w-5 relative h-5" alt="" src="/octagon.svg" />
              <div className="relative tracking-[0.15px] leading-[20px] font-semibold">
                Social Media Strategy
              </div>
            </div>
            <div className="rounded [background:linear-gradient(117.58deg,_rgba(215,_237,_237,_0.16),_rgba(205,_235,_235,_0))] shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.05),_0px_1px_3px_rgba(0,_0,_0,_0.05)] hidden flex-row items-center justify-end py-1 px-2 text-xs text-noble-black-300 font-inter border-[1px] border-solid border-glass-stroke">
              <div className="relative tracking-[0.15px] leading-[18px]">
                <span className="font-semibold">⌘</span>
                <span className="font-semibold font-body-l-medium"> S</span>
              </div>
            </div>
          </div>
          <div className="w-[296px] rounded-lg h-12 flex flex-row items-center justify-start py-3.5 px-4 box-border gap-[16px] text-noble-black-400">
            <div className="flex-1 flex flex-row items-center justify-start gap-[16px]">
              <img className="w-5 relative h-5" alt="" src="/pluscircle.svg" />
              <div className="relative tracking-[0.15px] leading-[20px] font-semibold">
                Add new project
              </div>
            </div>
            <div className="rounded [background:linear-gradient(117.58deg,_rgba(215,_237,_237,_0.16),_rgba(205,_235,_235,_0))] shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.05),_0px_1px_3px_rgba(0,_0,_0,_0.05)] hidden flex-row items-center justify-end py-1 px-2 text-xs text-noble-black-300 font-inter border-[1px] border-solid border-glass-stroke">
              <div className="relative tracking-[0.15px] leading-[18px]">
                <span className="font-semibold">⌘</span>
                <span className="font-semibold font-body-l-medium"> S</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start p-2">
        <div className="self-stretch rounded-2xl [background:linear-gradient(117.58deg,_rgba(215,_237,_237,_0.16),_rgba(205,_235,_235,_0))] flex flex-row items-center justify-between p-4 border-t-[1px] border-solid border-glass-stroke">
          <div className="flex flex-row items-center justify-start gap-[16px]">
            <img
              className="w-12 relative rounded-xl h-12"
              alt=""
              src="/avatar1.svg"
            />
            <div className="flex flex-col items-start justify-start gap-[4px]">
              <div className="relative tracking-[0.15px] leading-[24px] font-semibold">
                Ryan Lee
              </div>
              <div className="relative text-xs tracking-[0.15px] leading-[18px] font-medium text-stem-green-500">
                Premium
              </div>
            </div>
          </div>
          <img className="w-6 relative h-6" alt="" src="/cog.svg" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar1;
