import React from 'react'

function SubHeading({title,explanation}) {
    return(
      <div className='w-full flex flex-col items-start justify-start border-[10px] text-left text-base m-2'>
        <h3 className='m-1'>{title}</h3>
        <p className='m-1'>{explanation}</p>
        
      </div>
    )
  }
  
  export default SubHeading



