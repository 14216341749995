import React from 'react';

const Modal = ({ isOpen, closeModal, children }) => {
  const modalStyles = {
    overlay: `fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center ${
      isOpen ? 'block' : 'hidden'
    }`,
    content: 'bg-white p-6 rounded shadow-md max-w-screen-md max-h-[80vh] overflow-y-auto',
    closeButton: 'absolute top-2 right-2 text-gray-500',
  };

  return (
    <div className={modalStyles.overlay} onClick={closeModal}>
      <div className={modalStyles.content} onClick={(e) => e.stopPropagation()}>
        <button className={modalStyles.closeButton} onClick={closeModal}>
          X
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
