export const googleRequest = () => {
  const rootUrl = "https://accounts.google.com/o/oauth2/v2/auth";
  // different redirect url
  const redirect_uri = `https://artykal.buxa.ai/google_oauth`;
  // const redirect_uri = `http://localhost:3000/google_Oauth`;

  // user client_id from google developer console
  const client_id =
    "580349787533-mcctkbcdgndbhq09j1evmg1jdtifbeqk.apps.googleusercontent.com";
    
  const access_type = "offline";
  const response_type = "code";
  const prompt = "consent";
  const scope = [
    "https://www.googleapis.com/auth/userinfo.profile",
    "https://www.googleapis.com/auth/userinfo.email",
  ].join(" ");

  return {rootUrl,redirect_uri,client_id,access_type,response_type,prompt,scope};
}
