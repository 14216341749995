import React from 'react'

function Loader() {
  return (
    <div className='w-full h-full flex flex-row justify-center items-center'>
       <div className='w-[50px] h-[50px] animate-spinner relative left-[1rem]' style={{
      borderColor: 'black',
      borderWidth: '3px',
      borderStyle:'solid',
      borderTopColor: 'white',
      borderRadius: '50%',
    }}>
    </div>
    </div>
  )
}

export default Loader