import axios from "axios";

export const fetchHistory = async () => {
  try {
    const email = localStorage.getItem('email');
    console.log("email in history",email);
    if (!email) {
      console.error('Email not found in local storage');
      return;
    }
    const response = await axios.get(`https://artykal-backend-4janl.ondigitalocean.app/history?email=${email}`);
    const historyData = response.data.articles;
    console.log(historyData,"articles");
    return historyData;
  } catch (error) {
    console.error(error);
  }
};
