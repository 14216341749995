import React, { useState } from 'react';
import Modal from '../components/Modal';

function History({ historyData }) {
  const [selectedHistory, setSelectedHistory] = useState(null);

  const openModal = (history) => {
    setSelectedHistory(history);
  };

  const closeModal = () => {
    setSelectedHistory(null);
  };

  return (
    <div className='w-full h-[80vh] text-black bg-noble-black-600 p-4'>
      {historyData?.map((history, index) => (
        <div
          key={index}
          className="bg-noble-black-600 p-4 rounded-lg mb-4 cursor-pointer"
          onClick={() => openModal(history)}
        >
          <div className="text-neutral-50 text-lg">{history.perspective}</div>
          <div className="text-neutral-50 text-md">{history.topic}</div>
        </div>
      ))}

      {selectedHistory && (
        <Modal isOpen={true} closeModal={closeModal}>
          <div className='bg-noble-black-600 p-4'>
            <div className="text-neutral-50 text-2xl mb-2">{selectedHistory.perspective}</div>
            <div className="text-neutral-50 text-md mb-2">{selectedHistory.topic}</div>
            {selectedHistory?.context?.map((con, i) => (
              <div key={i} className="text-neutral-50 mb-2">
                {con}
              </div>
            ))}
            {JSON.parse(selectedHistory.generatedContent).map((gen, j) => (
              <div key={j} className="mb-2">
                <div className="text-neutral-50 text-lg">{gen.title}</div>
                <div className="text-neutral-50">{gen.explanation}</div>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
}

export default History;
