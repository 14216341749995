import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import DesktopNewNew from './DesktopNewNew';


function Google_auth({ onAuthentication}) {
  const [authenticated, setAuthenticated] = useState(false);
  const navigate=useNavigate()
  const params=new URLSearchParams(window.location.search);

  const loginUser = async () => {
    try {
      const body = {
        code: params.get('code'),
      };
  
      const { data } = await axios.post(`https://artykal-backend-4janl.ondigitalocean.app/google_oauth`, body);
      const responseData = data;

      if (responseData.success) {
        const user = {
          token: responseData.token,
        };

        setAuthenticated(true);

        if (onAuthentication) {
          onAuthentication(user);
        }

        // Store the token in localStorage
        localStorage.setItem('userToken', responseData.token);
        localStorage.setItem('email',(responseData.Email));

        navigate('/');
      } else {
        toast.error(responseData.message || 'Authentication failed.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Authentication failed. Please try again.');
    }
  };
  
  useEffect(()=>{
    console.log(params);
  loginUser()
  },[])
  return (
    <div>Redirecting to homepage</div>
  )
}

export default Google_auth
