import { useState, useCallback, useEffect } from "react";
import Sidebar1 from "../components/Sidebar1";
import PortalDrawer from "../components/PortalDrawer";
import axios from "axios";
import SubHeading from "../components/subHeading";
import Loader from "../components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Auth from "../auth/Auth";
import { googleRequest } from "../helper/googleRequest";
import History from "./History";
import { fetchHistory } from "../helper/fetchHistory";

const DesktopNewNew = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [perspective, setPerspective] = useState("positive");
  const [topic, setTopic] = useState("");
  const [url, setUrl] = useState([]);
  const [contextData, setContextData] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputList, setInputList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Artificium");
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("email")
  );
  const [historyData,setHistoryData] = useState([]);
  console.log("localStorage......", localStorage.getItem("email"))

  

  const handleTabClick = (tab) => {
    if (selectedTab !== tab) {
      console.log("selectedTab", selectedTab, tab);
      setSelectedTab(tab);

    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openSidebar = useCallback(() => {
    setSidebarOpen(true);
  }, []);

  const closeSidebar = useCallback(() => {
    setSidebarOpen(false);
  }, []);

  const handleGoogleLogin = () => {
    const {
      rootUrl,
      redirect_uri,
      client_id,
      access_type,
      response_type,
      prompt,
      scope,
    } = googleRequest();
    window.open(
      `${rootUrl}?redirect_uri=${redirect_uri}&client_id=${client_id}&access_type=${access_type}&response_type=${response_type}&prompt=${prompt}&scope=${scope}`,
      "_self"
    );
  };

  const handleLogout = () => {
    // Handle user logout (clear token, update isLoggedIn state, etc.)
    localStorage.removeItem("email");
    setIsLoggedIn(false);
  };

  const handleTopicChange = (event) => {
    setTopic(event.target.value);
  };

  const handlePerspectiveChange = (event) => {
    setPerspective(event.target.value);
  };

  const handleUrlChange = (event) => {
    const newText = event.target.value;
    setContextData(newText);
  };

  const addValue = () => {
    if (contextData.length >= 1200) {
      console.log("ADDING VALUE");
      setUrl([...url, contextData]);
      setContextData("");
    }
  };

  const handleAddItem = () => {
    if (
      contextData.trim() !== "" &&
      contextData.length >= 1200 &&
      contextData.length <= 6000
    ) {
      setInputList([...inputList, contextData]);
      addValue();
      setContextData("");
    } else {
      toast.error("Text length should be between 1200 and 6000 characters.");
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleDeleteItem = (index) => {
    const updatedList = [...inputList];
    updatedList.splice(index, 1);
    setInputList(updatedList);
  };

  const callHistory = async (e)=>{
    e.preventDefault()
    const historyInfo = await fetchHistory();
    setHistoryData(historyInfo);
    setSelectedTab("History")
  }
  const fetchData = async () => {
    if (!isLoggedIn) {
      console.log("inside isLoggedIn");
      handleGoogleLogin();
    } else {
      console.log("inside else ");
      try {
        setLoading(true);
        console.log("url: ", url);
        if (url.length < 2) {
          toast.error("At least 2 URLs/Text are required.");
          return;
        }
        if (!topic.trim()) {
          toast.error("Topic cannot be empty.");
          return;
        }
        const userEmail = localStorage.getItem('email');
        console.log("userEmail: ", userEmail);
        const headers = {
          'Content-Type': 'application/json',
          'user-email': userEmail,  
        };
        const body = {
          topic: topic,
          url: url,
          perspective: perspective,
        };

        const response = await axios.post("https://artykal-backend-4janl.ondigitalocean.app/", body,{headers});
        const responseData = response.data;

        console.log("--------------", responseData);
        // Update the state based on the API response
        setResponseData(responseData.article);
        setPerspective(responseData.perspective || "");
        setTopic(responseData.topic || "");
        setUrl(responseData.url || []);

        responseData.map((article) => {});
        console.log(responseData);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <div className="w-full relative rounded-3xl bg-noble-black-600 flex flex-row items-start justify-start p-3 box-border gap-[12px] text-center text-base text-noble-black-0 font-body-l-medium">
        <div className="w-[312px] rounded-xl bg-noble-black-800 h-[1056px] flex flex-col items-start justify-start md:hidden">
          <div className="self-stretch flex flex-row items-center justify-between p-6 border-b-[1px] border-solid border-noble-black-700">
            <div className="flex flex-row items-center justify-start gap-[16px]">
              <img
                className="w-12 relative rounded-xl h-12"
                alt=""
                src="/avatar.png"
              />
              <div className="flex flex-col items-start justify-start gap-[4px]">
                <div className="relative tracking-[0.15px] leading-[24px] font-semibold">
                  Artykal
                </div>
              </div>
            </div>
            <img className="w-4 relative h-4" alt="" src="/chevrondown.svg" />
          </div>
          <div className="self-stretch flex flex-col items-start justify-start py-6 px-2 gap-[24px] text-left text-xs text-noble-black-400 border-b-[1px] border-solid border-noble-black-700">
            <div className="self-stretch flex flex-row items-start justify-start py-0 px-4">
              <div className="relative tracking-[0.15px] leading-[18px] font-semibold">
                GENERAL
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[8px] text-center text-noble-black-300 font-inter">
              <div className="w-[296px] rounded-lg h-12 flex flex-row items-center justify-start py-3.5 px-4 box-border gap-[16px]">
                <div className="flex-1 flex flex-row items-center justify-start gap-[16px]">
                  <img className="w-5 relative h-5" alt="" src="/search.svg" />
                  <input
                    className="[border:none] [outline:none] font-semibold font-body-l-medium text-sm bg-[transparent] relative tracking-[0.15px] leading-[20px] text-noble-black-100 text-center"
                    placeholder="Search"
                    type="text"
                  />
                </div>
                <div className="rounded [background:linear-gradient(117.58deg,_rgba(215,_237,_237,_0.16),_rgba(205,_235,_235,_0))] shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.05),_0px_1px_3px_rgba(0,_0,_0,_0.05)] flex flex-row items-center justify-end py-1 px-2 border-[1px] border-solid border-glass-stroke">
                  <div className="relative tracking-[0.15px] leading-[18px]">
                    <span className="font-semibold">⌘</span>
                    <span className="font-semibold font-body-l-medium"> S</span>
                  </div>
                </div>
              </div>
              <div className="w-[296px] rounded-lg h-12 flex flex-row items-center justify-start py-3.5 px-4 box-border gap-[16px] text-sm text-noble-black-100 font-body-l-medium">
                <div className="flex-1 flex flex-row items-center justify-start gap-[16px]">
                  <img
                    className="w-5 relative h-5"
                    alt=""
                    src="/creditcard.svg"
                  />
                  <div className="relative tracking-[0.15px] leading-[20px] font-semibold">
                    Billing
                  </div>
                </div>
                <div className="rounded [background:linear-gradient(117.58deg,_rgba(215,_237,_237,_0.16),_rgba(205,_235,_235,_0))] shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.05),_0px_1px_3px_rgba(0,_0,_0,_0.05)] hidden flex-row items-center justify-end py-1 px-2 text-xs text-noble-black-300 font-inter border-[1px] border-solid border-glass-stroke">
                  <div className="relative tracking-[0.15px] leading-[18px]">
                    <span className="font-semibold">⌘</span>
                    <span className="font-semibold font-body-l-medium"> S</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start py-6 px-2 gap-[24px] text-left text-xs text-noble-black-400">
            <div className="self-stretch flex flex-row items-start justify-start py-0 px-4">
              <div className="relative tracking-[0.15px] leading-[18px] font-semibold">
                PROJECTS
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[8px] text-center text-sm text-noble-black-100">
              <div className="w-[296px] rounded-lg [background:linear-gradient(117.58deg,_rgba(215,_237,_237,_0.16),_rgba(205,_235,_235,_0))] box-border h-12 flex flex-row items-center justify-start py-3.5 px-4 gap-[16px] border-t-[1px] border-solid border-glass-stroke">
                <div className="flex-1 flex flex-row items-center justify-start gap-[16px]">
                  <div className="w-5 relative [filter:drop-shadow(0px_10px_15px_-3px_rgba(182,_240,_156,_0.16))_drop-shadow(0px_4px_6px_-4px_rgba(182,_240,_156,_0.16))] h-5">
                    <div className="absolute h-[82.5%] w-[82.5%] top-[9%] right-[8.5%] bottom-[8.5%] left-[9%] rounded-10xs box-border border-[1.5px] border-solid border-stem-green-500" />
                  </div>
                </div>
                <div className="rounded [background:linear-gradient(117.58deg,_rgba(215,_237,_237,_0.16),_rgba(205,_235,_235,_0))] shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.05),_0px_1px_3px_rgba(0,_0,_0,_0.05)] hidden flex-row items-center justify-end py-1 px-2 text-xs text-noble-black-300 font-inter border-[1px] border-solid border-glass-stroke">
                  <div className="relative tracking-[0.15px] leading-[18px]">
                    <span className="font-semibold">⌘</span>
                    <span className="font-semibold font-body-l-medium"> S</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start p-2">
            <div className="self-stretch rounded-2xl [background:linear-gradient(117.58deg,_rgba(215,_237,_237,_0.16),_rgba(205,_235,_235,_0))] flex flex-row items-center justify-between p-4 border-t-[1px] border-solid border-glass-stroke">
              <div className="flex flex-row items-center justify-start gap-[16px]">
                <img
                  className="w-12 relative rounded-xl h-12"
                  alt=""
                  src="/avatar1.svg"
                />
                <div className="flex flex-col items-start justify-start gap-[4px]">
                  <div className="relative tracking-[0.15px] leading-[24px] font-semibold">
                    Ryan Lee
                  </div>
                  <div className="relative text-xs tracking-[0.15px] leading-[18px] font-medium text-stem-green-500">
                    Premium
                  </div>
                </div>
              </div>
              <img className="w-6 relative h-6" alt="" src="/cog.svg" />
            </div>
          </div>
        </div>




















        <div className="h-full flex-1 bg-noble-black-600 overflow-y-auto flex flex-col items-start justify-start gap-[24px] max-h-[1056px] text-left text-xl">
          <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
            <div className="self-stretch rounded-xl bg-noble-black-800 flex flex-col items-start justify-start min-w-[362px]">
              <div className="self-stretch flex flex-row items-center justify-between py-3 px-6 border-b-[1px] border-solid border-noble-black-700">
                <div className="w-[321px] flex flex-row items-center justify-start gap-[24px]">
                  <button
                    className="cursor-pointer [border:none] p-0 bg-[transparent] h-5 w-5 relative hidden lg:hidden md:flex mq350small:flex"
                    onClick={openSidebar}
                  >
                    <img
                      className="absolute h-[48.5%] w-[73%] top-[25.5%] right-[13.5%] bottom-[26%] left-[13.5%] max-w-full overflow-hidden max-h-full"
                      alt=""
                      src="/menu.svg"
                    />
                  </button>
                  <div className="flex flex-col items-start justify-start gap-[4px]">
                    <b className="relative leading-[32px]">Artykal</b>
                    <div className="relative text-sm tracking-[0.15px] leading-[20px] font-medium text-noble-black-300 mq800:hidden">
                    Revolutionize Writing: Experience the Future of Content Creation
                    </div>
                  </div>
                </div>
                <div className="w-[310px] flex flex-row items-center justify-end gap-[12px] text-xs text-noble-black-400 sm:hidden mq350small:hidden mq540:hidden">
                  <div className="flex flex-row items-start justify-start sm:hidden mq800:hidden">
                    <div className="">
                      {!isLoggedIn ? (
                        <Auth/>
                      ):(
                        <button className="bg-noble-black-600 text-white" onClick={handleLogout}>logout</button>
                      )
                    }
                    </div>
                  </div>
                  <button className="cursor-pointer [border:none] py-2 px-4 bg-[transparent] rounded-3xs h-10 flex flex-row items-center justify-center box-border gap-[10px]">
                    <div className="relative text-sm tracking-[0.15px] leading-[20px] font-semibold font-body-l-medium text-noble-black-400 text-left">
                      Share
                    </div>
                    <img
                      className="w-5 relative h-5"
                      alt=""
                      src="/alertcircle.svg"
                    />
                  </button>
                  <button className="cursor-pointer [border:none] py-2 px-4 bg-noble-black-600 w-10 rounded-3xs h-10 flex flex-row items-center justify-center box-border">
                    <img className="w-5 relative h-5" alt="" src="/edit.svg" />
                  </button>
                </div>
              </div>
              <div className="self-stretch h-20 flex flex-row items-center justify-between p-6 box-border text-center text-sm text-noble-black-800">
              <div className="flex flex-row items-start justify-start gap-[12px]">
      <div className="flex flex-row items-center justify-start py-0 px-2 relative gap-[12px]">
        <img
          className="w-5 relative h-5 z-[0]"
          alt=""
          src="/artificium.svg"
        />
        <div
          className={`relative tracking-[0.15px] leading-[20px] font-semibold cursor-pointer ${selectedTab === 'Artificium' ? 'text-stem-green-500' : 'text-noble-black-100'}`}
          onClick={() => handleTabClick('Artificium')}
        >
          Artificium
        </div>
        <div
          className={`w-[calc(100%_+_1px)] absolute my-0 mx-[!important] right-[-1px] bottom-[-30px] left-[0px] rounded-t rounded-b-none ${
            selectedTab === 'Artificium'
              ? 'bg-stem-green-500'
              : 'bg-noble-black-100'
          } `}
        />
      </div>

      <div className="flex flex-row items-center justify-start py-0 px-2 gap-[12px]">
        <img
          className="w-5 relative h-5"
          alt=""
          src="/commentcircle.svg"
        />
        <div
          className={`relative tracking-[0.15px] leading-[20px] font-semibold cursor-pointer ${
            selectedTab === 'History'
              ? 'text-stem-green-500'
              : 'text-noble-black-100'
          }`}
          // onClick={() => handleTabClick('History')}
          onClick={callHistory}
        >
          History
        </div>
      </div>
    </div>
            <div className="flex flex-row justify-end">
                  <img
                    className="w-5 bg-white relative h-5 z-[0]"
                    alt=""
                    src="/info (1).png"
                    onClick={openModal}
                  />
                </div>
                {isModalOpen && (
                  <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
                    <div
                      className="absolute top-0 left-0 w-full h-full bg-gray-800 opacity-50"
                      onClick={closeModal}
                    ></div>
                    <div className="p-4 bg-white [blackrounded-md z-10">
                      <p>
                        Criteria for Input:
                        <br />
                        1. Context Length:
                        <br />
                        - Should be between 1200 and 6000 characters
                        (inclusive).
                        <br />
                        <br />
                        2. Number of Contexts or URLs:
                        <br />
                        - At least 2 contexts or URLs should be provided.
                        <br />
                        <br />
                        Please ensure that the input adheres to these criteria
                        for the desired functionality.
                      </p>
                      <button onClick={closeModal}>Close</button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            </div>
            {selectedTab === 'History' ? <History historyData={historyData}/> :
            <>
            <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px_20px] min-w-[386px]">
              <div className="flex-1 rounded-xl bg-noble-black-800 h-[74px] flex flex-row items-center justify-between py-6 px-5 box-border min-w-[362px]">
                <input
                  className="[border:none] [outline:none] font-medium font-body-l-medium w-[100%] text-base bg-[transparent] flex flex-row items-start justify-start py-3 px-0 text-noble-black-300"
                  placeholder={`Add Topic & Specify Length`}
                  type="text"
                  value={topic}
                  onChange={handleTopicChange}
                />
                <div className="w-[55px] relative h-6">
                  <button className="cursor-pointer [border:none] p-0 bg-[transparent] absolute top-[0px] left-[0px] w-6 h-6">
                    <img
                      className="absolute h-[58.33%] w-[58.33%] top-[20.83%] right-[20.83%] bottom-[20.83%] left-[20.83%] rounded-12xs max-w-full overflow-hidden max-h-full"
                      alt=""
                      src="/icon1.svg"
                    />
                  </button>
                  <button className="cursor-pointer [border:none] p-0 bg-[transparent] absolute top-[0px] left-[31px] w-6 h-6">
                    <img
                      className="absolute h-[66.67%] w-[58.33%] top-[16.67%] right-[20.83%] bottom-[16.67%] left-[20.83%] max-w-full overflow-hidden max-h-full"
                      alt=""
                      src="/icon2.svg"
                    />
                  </button>
                </div>
              </div>
              <div className="flex-1 rounded-xl bg-noble-black-800 h-[74px] flex flex-row items-center justify-between py-6 px-5 box-border min-w-[362px]">
                <select
                  className="[border:none] [outline:none] font-medium font-body-l-medium w-[100%] text-base bg-[transparent] flex flex-row items-start justify-start py-3 px-0 text-noble-black-300"
                  value={perspective}
                  onChange={handlePerspectiveChange}
                >
                  <option value="">Select Perspective</option>
                  <option value="positive">Positive</option>
                  <option value="negative">Negative</option>
                </select>
                
              </div>
              <div className="flex-1 rounded-xl bg-noble-black-800 h-[74px] flex flex-row items-center justify-between py-6 px-5 box-border min-w-[362px]">
                <input
                  className="[border:none] [outline:none] font-medium font-body-l-medium text-base bg-[transparent] w-[100%] flex flex-row items-start justify-start py-3 px-0  text-[#fff]"
                  placeholder="Provide context/url"
                  type="text"
                  value={contextData}
                  onChange={handleUrlChange}
                />
                <div className="w-[108px] ml-[25px] relative h-12">
                  <button className="cursor-pointer [border:none] p-0 bg-[transparent] absolute top-[0px] left-[0px] w-6 h-6">
                    <img
                      className="mt-[12px] absolute h-[58.33%] w-[58.33%] top-[20.83%] right-[20.83%] bottom-[20.83%] left-[20.83%] rounded-12xs max-w-full overflow-hidden max-h-full"
                      alt=""
                      src="/icon1.svg"
                      onClick={handleAddItem}
                    />
                  </button>
                  <button
                    onClick={fetchData}
                    className={`cursor-pointer [border:none] py-2 px-6 bg-noble-black-800 absolute top-[0px] left-[60px] rounded-xl w-12 h-12 flex flex-row items-center justify-center box-border ${
                      url.length < 2 ? "cursor-not-allowed cursor-" : ""
                    }`}
                  >
                    <img
                      className="w-6 relative h-6"
                      alt=""
                      src="/paperplane.svg"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[25px]">
              <div className="flex-1 rounded-3xl bg-noble-black-800 flex flex-col items-start justify-start py-6 px-[31.5px] box-border 
              gap-[28px] min-w-[362px]">
                <div style={{ display: "flex", overflowX: "auto", gap: "12px" }}>
                  {inputList.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-row items-center justify-start"
                    >
                      <div className="w-[300px] relative tracking-[0.15px] font-medium whitespace-pre-wrap flex items-center shrink-0">
                        {truncateText(item, 50)}
                      </div>
                      <button
                        className="bg-black text-white"
                        onClick={() => handleDeleteItem(index)}
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-between text-center text-base">
              {loading && <Loader />}
              <div className="flex-1 flex flex-col items-start justify-between min-w-[362px]">
                {responseData?.map((article, index) => (
                  <div key={index}>
                    <div className="w-full flex flex-col items-start justify-start border-[10px] text-left text-base m-2">
                      <h3 className="m-1">{article?.title}</h3>
                      <p className="m-1">{article?.explanation}</p>
                    </div>
                    {article.subheadings?.map((subHead) => (
                      <SubHeading
                        title={subHead.title}
                        explanation={subHead.explanation}
                      />
                    ))}
                  </div>
                ))}

              </div>
              <div className="w-2.5 relative rounded-3xs bg-noble-black-500 h-[640px]" />
            </div>
            </>
            }
          
        </div>
      </div>
          
            
      {/* {isSidebarOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeSidebar}
        >
          <Sidebar1 onClose={closeSidebar} />
        </PortalDrawer>
      )} */}
    </>
  );
};

export default DesktopNewNew;
