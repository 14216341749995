import React, { useEffect } from 'react'
import {toast } from 'react-toastify';
import { googleRequest } from '../helper/googleRequest';


function Auth() {
  const handleGoogleLogin = ()=>{
    const {rootUrl,redirect_uri,client_id,access_type,response_type,prompt,scope} = googleRequest();
    console.log("google login",googleRequest());
    window.open(`${rootUrl}?redirect_uri=${redirect_uri}&client_id=${client_id}&access_type=${access_type}&response_type=${response_type}&prompt=${prompt}&scope=${scope}`, "_self");
  }

  return (
    <button className='bg-noble-black-600 text-white' onClick={handleGoogleLogin}>Login</button>
  )
}

export default Auth